<template>
  <div class="add-client-dialog">
    <el-dialog
      title="快速添加客户"
      :visible.sync="dialogVisible"
      center
      :show-close="false"
      width="500px"
    >
      <div class="content-box">
        <div class="row-box">
          <p class="label">
            <span>* </span>
            客户类型
          </p>
          <el-select v-model="clientForm.type" filterable placeholder="请选择客户类型">
            <el-option
              v-for="item in typeList2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            客户名称
          </p>
          <el-input
            type="text"
            v-model="clientForm.name"
            placeholder="请输入客户名称"
          />
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            联系方式
          </p>
          <el-input
            type="text"
            v-model="clientForm.phone"
            placeholder="请输入联系方式"
          />
        </div>
        <div class="row-box">
          <p class="label">客户编号</p>
          <el-input
            type="text"
            v-model="clientForm.num"
            placeholder="请输入客户编号"
          />
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel2">取 消</button>
        <button class="btn2" @click="submit2">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addCustomer } from "@/FackApi/api/project.js";
export default {
  name: "addClientDialog",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: true,
      clientForm: {
        type: 1,
        name: "",
        phone: "",
        num: "",
      },
      typeList2: [
        { label: "个人类", value: 1 },
        { label: "企业类", value: 2 },
      ],
      throttleNum: 0,
      flag: null, // 计时器
    };
  },
  created() {},
  mounted() {},
  methods: {
    cancel2() {
      this.$parent.showClientDialog = false;
      this.$parent.init();
    },
    // 弹框保存
    async submit2() {
      if (this.throttleNum != 0) return;
      this.throttleNum = 1;
      let that = this;
      this.flag = setInterval(()=>{
        that.throttleNum ++;
        if (that.throttleNum == 2) {
          that.throttleNum = 0;
          clearInterval(this.flag);
        }
      },1000)
      let verifyObj = {
        name: "客户名称",
        phone: "联系方式",
      };
      for (let key in verifyObj) {
        if (!this.clientForm[key]) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      let isPhone = /^1[3456789]\d{9}$/;
      var isMob = /^((0\d{2,3})-)?(\d{7,8})$/;
      if (
        isPhone.test(this.clientForm.phone) ||
        isMob.test(this.clientForm.phone)
      ) {
        let params = {
          code: this.clientForm.num,
          name: this.clientForm.name,
          type: this.clientForm.type,
          address: "",
          email: "",
          nature: "",
          tags: "",
          provinceId: "",
          province: "",
          city: "",
          cityId: "",
          contact: this.clientForm.phone,
          industry: "",
          remark: "",
          contacts: [],
          files: [],
        };
        let res = await addCustomer(params);
        if (res.code == 0) {
          this.cancel2();
        }
      } else {
        this.$message.warning(`请正确输入联系方式（手机号或座机号）！`);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.flag);
  },
};
</script>

<style lang="less" scoped>
.add-client-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 20px;
        .content-box {
          .row-box {
            width: 100%;
            .label {
              span {
                color: red;
              }
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          margin-top: 30px;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
